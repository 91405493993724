import React, { Fragment } from "react";
import { Root, Routes, SiteData } from "react-static";
import { hot } from "react-hot-loader";

import "reset-css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./app.css";

import Header from "./components/header";

class App extends React.Component {
  componentDidMount = () => {
    console.log(
      "🎉 Site by Muhammad Mustadi",
      "🌏 https://mathdro.id",
      "💬 https://twitter.com/mathdroid",
      "👏"
    );
  };

  render() {
    return (
      <Root>
        <Fragment>
          <Header />
          <main className="content">
            <Routes />
          </main>
          <SiteData>
            {({ data }) => (
              <footer>
                <span>{data.footer}</span>
              </footer>
            )}
          </SiteData>
        </Fragment>
      </Root>
    );
  }
}

export default hot(module)(App);
