import React from "react";
import styled from "@emotion/styled";
import { Link as ReachLink } from "@reach/router";

const isActive = props => ({ isCurrent }) => {
  return isCurrent
    ? { isActive: true, className: `${props.className} active` }
    : null;
};

const Link = props => <ReachLink getProps={isActive(props)} {...props} />;

const ModalStyle = styled.ul`
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const LinkStyle = styled(Link)`
  color: black;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  &.active {
    background: rgba(255, 69, 0, 0.2);
    font-weight: bold;
  }
`;

const PosedModal = ModalStyle;

const PosedLi = styled.li`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Modal = ({ isOpen, toggleModal }) => (
  <PosedModal
    style={{ display: isOpen ? "flex" : "none" }}
    onClick={toggleModal}
  >
    <PosedLi>
      <LinkStyle exact to="/">
        Home
      </LinkStyle>
    </PosedLi>
    <PosedLi>
      <LinkStyle to="/services">Services</LinkStyle>
    </PosedLi>
    <PosedLi>
      <LinkStyle to="/team">Team</LinkStyle>
    </PosedLi>
    <PosedLi>
      <LinkStyle to="/faq">F.A.Q.</LinkStyle>
    </PosedLi>
    <PosedLi>
      <LinkStyle to="/contact">Contact</LinkStyle>
    </PosedLi>
  </PosedModal>
);

export default Modal;
