import React from "react";
import { render, hydrate } from "react-dom";
import { cache } from "emotion";
import { CacheProvider } from "@emotion/core";

// Your top level component
import App from "./App";

// Export your top level component as JSX (for static rendering)
export default App;

// Render your app
if (typeof document !== "undefined") {
  const renderMethod = module.hot ? render : hydrate || render;
  const renderComp = Comp => {
    renderMethod(
      <CacheProvider value={cache}>
        <Comp />
      </CacheProvider>,
      document.getElementById("root")
    );
  };

  // Render!
  renderComp(App);
}
