import React from "react";
import styled from "@emotion/styled";
import { Link as ReachLink } from "@reach/router";

import Button from "../button";
import Modal from "./modal";

const isActive = props => ({ isCurrent }) => {
  return isCurrent
    ? { isActive: true, className: `${props.className} active` }
    : null;
};

const Link = props => <ReachLink getProps={isActive(props)} {...props} />;

const HeaderStyle = styled.header`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: white;
  height: 4rem;
  a {
    font-family: "Atlas", monospace;
  }
  nav > a {
    height: 2rem;
    margin: 0 1rem;
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 850px;
    width: 100%;
    height: 2rem;
    margin-bottom: 1rem;
    a {
      text-transform: uppercase;
      font-size: 11pt;
      line-height: 2rem;
      border-bottom: solid 1px transparent;
      opacity: 1;
      transition: text-shadow 0.3s, border-color 0.3s;
      img {
        vertical-align: middle;
        height: 1rem;
      }
    }
    button {
      display: none;
      z-index: 20;
      margin-top: 0;
      margin-bottom: 0;
    }
    @media (max-width: 850px) {
      justify-content: space-between;
      & > a:not(.logo-link) {
        display: none;
      }
      button {
        display: unset;
      }
    }
  }
`;

const StyledLink = styled(Link)`
  &:hover {
    text-shadow: 0 0 2rem rgba(255, 69, 0, 0.75);
    border-bottom: solid 1px rgba(0, 0, 0, 0.4);
  }
  &.active {
    border-bottom: solid 1px black;
  }
`;

export default class Header extends React.Component {
  state = {
    isModalOpen: false
  };

  toggleModal = () => {
    this.setState(state => ({ isModalOpen: !state.isModalOpen }));
  };

  render() {
    const { isModalOpen } = this.state;
    return (
      <HeaderStyle>
        <nav>
          <StyledLink href="/services" to="/services">
            Services
          </StyledLink>
          <StyledLink href="/team" to="/team">
            Team
          </StyledLink>
          <StyledLink className="logo-link" exact href="/" to="/">
            <img alt="In Other Words logo" src="/media/logotype.png" />
          </StyledLink>
          <StyledLink href="/faq" to="/faq">
            F.A.Q.
          </StyledLink>
          <StyledLink href="/contact" to="/contact">
            Contact
          </StyledLink>
          <Button
            onClick={this.toggleModal}
            color={isModalOpen ? "orangered" : "white"}
            bgColor={isModalOpen ? "white" : "orangered"}
          >
            {isModalOpen ? "Back" : "Menu"}
          </Button>
          <Modal isOpen={isModalOpen} toggleModal={this.toggleModal} />
        </nav>
      </HeaderStyle>
    );
  }
}
