import React from "react";
import styled from "@emotion/styled";

const Button = styled.button`
  font-family: "Atlas", monospace;
  font-weight: lighter;
  display: flex;
  flex: none;
  align-self: center;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  word-break: keep-all;
  line-height: 1;
  position: relative;
  text-align: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  background: ${props => props.bgColor || "white"};
  border: none;
  color: ${props => props.color || "orangered"};
  text-transform: uppercase;
  margin: 1rem;
  font-size: 11pt;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  &:hover,
  &:focus,
  &:active {
    color: ${props => props.color || "black"};
    border-color: ${props => props.bgColor || "black"};
  }

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:focus {
    outline: 1px solid rgba(255, 255, 255, 0.5);
    outline-offset: -4px;
  }

  &:active {
    transform: scale(0.99) translateY(4px);
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: white;
    box-shadow: none;
  }
`;

export default props => <Button type="button" {...props} />;
